
export const LINK_TYPES = (i18n, lng) => Object.freeze({
    FD: {label: i18n.t("general.link_fd", {lng}), value: 'FD'},
    DD: {label: i18n.t("general.link_dd", {lng}), value: 'DD'},
    FF: {label: i18n.t("general.link_ff", {lng}), value: 'FF'},
    DF: {label: i18n.t("general.link_df", {lng}), value: 'DF'},
  })

export const LINK_CATEGORY = (i18n, lng) => Object.freeze({
  Normal: {label: i18n.t("link_tab.link_category_normal", {lng}), value: -1},
  Conflit: {label: i18n.t("link_tab.link_category_conflict", {lng}), value: -2},
  Pilote: {label: i18n.t("link_tab.link_category_dominant", {lng}), value: -3},
})

// used for import
export const LinkMapping = {
  SF: 'FD',
  FF: 'FF',
  SS: 'DD',
  FS: 'FD'
}

// used for import
export const LinkCategoryMapping = {
  Normal: 'Normal',
  Conflict: 'Conflit',
  Dominant: 'Pilote',
}
  export default null;